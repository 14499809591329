import { useContext } from 'react';
import useSWR from 'swr';
import StaticCitiesContext from 'utils/context/static-cities-context';
import { fetchData } from '../helpers/fetchData';

export const getCities = async () => {
  return fetchData('list/cities', {
    all_city: 1,
  });
};

export const useCities = () => {
  const staticCities = useContext(StaticCitiesContext);

  const { data } = useSWR('list/cities', getCities, {
    initialData: staticCities,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    cities: data,
  };
};
