import React, { FC } from 'react';
import { GetStaticProps } from 'next';
import {
  ICandidateCountryFullDto,
  ICandidateSelectedCityDto,
  IEmploymentLevelDto,
  IEmploymentTypeDto,
  IJobCategoryDto,
  ISeniorityLevelDto,
} from '@nploy/ui-infrastructure';
import { IWorkModel } from '@nploy/ui/domain';
import {
  getCities,
  getFilters,
  getFiltersWorkModel,
  getJobCategories,
} from 'utils/buildHelpers/buildApiRequests';
import { StaticCategoriesContext, StaticCitiesContext } from 'utils/context';
import { StaticFiltersContext } from 'utils/context/static-filters-context';
import transformLocaleFormat from 'utils/i18n/transformLocaleFormat';
import { FindJobs } from 'components/FindJobs';
import { Layout } from 'components/layout';

const Home: FC<{
  jobCategories: IJobCategoryDto[];
  cities: ICandidateSelectedCityDto[];
  filters: {
    countries: ICandidateCountryFullDto[];
    employ_levels: IEmploymentLevelDto[];
    employ_types: IEmploymentTypeDto[];
    seniority_levels: ISeniorityLevelDto[];
    workModels: IWorkModel[];
  };
}> = ({ jobCategories, filters, cities }) => (
  <StaticFiltersContext.Provider value={filters}>
    <StaticCitiesContext.Provider value={cities}>
      <StaticCategoriesContext.Provider value={jobCategories}>
        <Layout>
          <FindJobs />
        </Layout>
      </StaticCategoriesContext.Provider>
    </StaticCitiesContext.Provider>
  </StaticFiltersContext.Provider>
);

export const getStaticProps: GetStaticProps = async (ctx) => {
  const locale = transformLocaleFormat(ctx.locale);
  const [jobCategories, filters, cities, workModels] = await Promise.all([
    getJobCategories(locale),
    getFilters(),
    getCities(),
    getFiltersWorkModel(),
  ]);

  return {
    props: {
      jobCategories,
      filters: {
        ...filters,
        workModels,
      },
      cities,
    },
  };
};

export default Home;
