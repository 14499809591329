import React, { useRef, useState, useEffect, FC, memo } from 'react';
import { Typography } from '@mui/material';
import { Button } from '@nploy/ui/web';
import classnames from 'classnames';
import { filtersValuesFields } from 'constants/filters-values-fields';
import useTranslate from 'utils/i18n/useTranslate';
import { useFilters } from 'utils/swr/hooks/useFilters';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { CheckboxPopperContent } from 'components/Filters/checkbox-popper-content';
import FilterContainer from '../FilterContainer/FilterContainer';
import { FiltersPopper } from '../filters-popper';
import { useWorkingTimeStyles } from './working-time.styles';

export const WorkingTime: FC = memo(() => {
  const classes = useWorkingTimeStyles();
  const { t } = useTranslate('home');

  const {
    filtersValues: { employment_levels: savedEmploymentLevels = [] },
    saveFieldsWithAllFilters,
  } = useFiltersValues();
  const {
    filters: { employ_levels: employmentLevels = [] },
  } = useFilters();

  const [open, setOpen] = useState(false);
  const [selectedEmploymentLevels, setSelectedEmploymentLevels] = useState([]);
  const anchorRef = useRef(null);

  useEffect(() => {
    setSelectedEmploymentLevels(savedEmploymentLevels);
  }, [open]);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleItemSelect = (id) => {
    if (selectedEmploymentLevels.includes(id)) {
      setSelectedEmploymentLevels(
        selectedEmploymentLevels.filter(
          (employmentLevelId) => employmentLevelId !== id,
        ),
      );
    } else {
      setSelectedEmploymentLevels([...selectedEmploymentLevels, id]);
    }
  };

  const handleSave = () => {
    saveFieldsWithAllFilters(
      { [filtersValuesFields.employment_levels]: selectedEmploymentLevels },
      handleCloseMenu,
    );
  };

  return (
    <FilterContainer>
      <Button
        ref={anchorRef}
        onClick={handleToggleMenu}
        color="inherit"
        className={classnames(classes.button, open && classes.activeButton)}
        size="small"
        id="change-working-times-button"
        area-label="change-working-times"
      >
        <Typography
          variant="button"
          className={classnames(
            classes.buttonText,
            !!savedEmploymentLevels.length && classes.buttonTextSuccess,
          )}
        >
          {t('filters.workingTimes.title')}{' '}
          {!!savedEmploymentLevels.length &&
            `(${savedEmploymentLevels.length})`}
        </Typography>
      </Button>

      <FiltersPopper
        open={open}
        currentAnchorEl={anchorRef.current}
        onListKeyDown={handleListKeyDown}
        onClose={handleCloseMenu}
        onSave={handleSave}
        popperContent={
          <CheckboxPopperContent
            items={employmentLevels.map(({ name, ...level }) => ({
              ...level,
              name: t(`filters.employmentLevels.${name}`),
            }))}
            selectedItems={selectedEmploymentLevels}
            onItemSelect={handleItemSelect}
          />
        }
        PaperProps={{
          id: 'change-working-times',
          'area-labelledby': 'change-working-times-button',
        }}
      />
    </FilterContainer>
  );
});
