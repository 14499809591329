import React, { useRef, useState, useEffect, FC, memo } from 'react';
import { Typography } from '@mui/material';
import { Button } from '@nploy/ui/web';
import classnames from 'classnames';
import { filtersValuesFields } from 'constants/filters-values-fields';
import useTranslate from 'utils/i18n/useTranslate';
import { useFilters } from 'utils/swr/hooks/useFilters';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import { hideJobTypeFilter, openJobTypeFilter } from 'store/reducers/uiReducer';
import { CheckboxPopperContent } from 'components/Filters';
import { FiltersPopper } from 'components/Filters/filters-popper';
import FilterContainer from '../FilterContainer/FilterContainer';
import { useJobTypeStyles } from './job-type.styles';

export const FiltersJobType: FC = memo(() => {
  const classes = useJobTypeStyles();
  const { t } = useTranslate('home');
  const showModal = useAppSelector(({ ui }) => ui.openJobTypeFilter);
  const dispatch = useAppDispatch();

  const {
    filtersValues: { employment_types: savedEmploymentTypes = [] },
    saveFieldsWithAllFilters,
  } = useFiltersValues();
  const {
    filters: { employ_types: employmentTypes = [] },
  } = useFilters();

  const [open, setOpen] = useState(false);
  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState([]);
  const anchorRef = useRef(null);
  useEffect(() => {
    setSelectedEmploymentTypes(savedEmploymentTypes);
  }, [open, showModal]);

  const handleToggleMenu = () => {
    dispatch(openJobTypeFilter());
  };

  const handleCloseMenu = () => {
    dispatch(hideJobTypeFilter());
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleItemSelect = (id) => {
    if (selectedEmploymentTypes.includes(id)) {
      setSelectedEmploymentTypes(
        selectedEmploymentTypes.filter(
          (employmentTypeId) => employmentTypeId !== id,
        ),
      );
    } else {
      setSelectedEmploymentTypes([...selectedEmploymentTypes, id]);
    }
  };
  const handleSave = () => {
    saveFieldsWithAllFilters(
      { [filtersValuesFields.employment_types]: selectedEmploymentTypes },
      handleCloseMenu,
    );
  };
  return (
    <FilterContainer>
      <Button
        ref={anchorRef}
        onClick={handleToggleMenu}
        color="inherit"
        className={classnames(classes.button, open && classes.activeButton)}
        size="small"
        id="change-job-types-button"
        area-label="change-job-types"
      >
        <Typography
          variant="button"
          className={classnames(
            classes.buttonText,
            !!savedEmploymentTypes.length && classes.buttonTextSuccess,
          )}
        >
          {t('filters.jobType.title')}{' '}
          {!!savedEmploymentTypes.length && `(${savedEmploymentTypes.length})`}
        </Typography>
      </Button>

      <FiltersPopper
        open={showModal}
        currentAnchorEl={anchorRef.current}
        onListKeyDown={handleListKeyDown}
        onClose={handleCloseMenu}
        onSave={handleSave}
        popperContent={
          <CheckboxPopperContent
            items={employmentTypes.map(({ name, ...type }) => ({
              ...type,
              name: t(`filters.jobType.${name}`),
            }))}
            selectedItems={selectedEmploymentTypes}
            onItemSelect={handleItemSelect}
          />
        }
        PaperProps={{
          id: 'change-job-types',
          'aria-labelledby': 'change-job-types-button',
        }}
      />
    </FilterContainer>
  );
});
