import React, { useState, useEffect, useCallback, FC } from 'react';
import { Chip } from '@mui/material';
import { ButtonIcon } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import palette from 'theme/palette';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { useJobCategories } from 'utils/swr/hooks/useJobCategories';
import { FilterItemMotion } from '../filter-item-motion';
import { JobCategoriesModal } from '../filters-job-categories-modal';
import { useFiltersJobCategories } from './filters-job-categories.styles';

const MAX_CATEGORIES = 3;

export const FiltersJobCategories: FC = () => {
  const classes = useFiltersJobCategories();

  const [selectedJobCategories, setSelectedJobCategories] = useState([]);
  const [isShowJobCategoriesModal, setShowJobCategoriesModal] = useState(false);

  const { jobCategories } = useJobCategories();
  const {
    filtersValues: { jobCategories: savedJobCategories },
    saveJobCategories,
  } = useFiltersValues();

  useEffect(() => {
    if (jobCategories) {
      const categories = jobCategories.filter(({ id }) =>
        (savedJobCategories || []).includes(id),
      );
      setSelectedJobCategories(categories);
    }
  }, [jobCategories, savedJobCategories]);

  const handleDelete = (category) => {
    const categories = selectedJobCategories.filter(
      ({ id }) => id !== category.id,
    );
    const categoriesIds = categories.map((categoryItem) => categoryItem.id);
    saveJobCategories(categoriesIds);
    setSelectedJobCategories(categories);
  };

  const handleOpenModal = useCallback(
    () => setShowJobCategoriesModal(true),
    [],
  );

  const handleCloseModal = useCallback(
    () => setShowJobCategoriesModal(false),
    [],
  );

  return (
    <>
      {selectedJobCategories.map((category) => (
        <FilterItemMotion key={category.id}>
          <Chip
            deleteIcon={
              <ButtonIcon
                disabled={selectedJobCategories.length === 1}
                className={classnames(
                  classes.deleteButton,
                  selectedJobCategories.length !== 1 && 'delete-icon',
                )}
              >
                <FontAwesomeIcon
                  icon={faTimes as IconProp}
                  color={palette.darkerGrey.main}
                  size="xs"
                />
              </ButtonIcon>
            }
            label={category.name}
            className={classes.chip}
            classes={{ deleteIcon: classes.deleteIcon }}
            onDelete={() => handleDelete(category)}
          />
        </FilterItemMotion>
      ))}

      {selectedJobCategories.length < MAX_CATEGORIES && (
        <FilterItemMotion>
          <ButtonIcon
            onClick={handleOpenModal}
            className={classes.buttonIcon}
            id="add-job-category-button"
            area-label="add-job-category"
          >
            <FontAwesomeIcon
              icon={faPlus as IconProp}
              color={palette.darkerGrey.main}
              size="xs"
            />
          </ButtonIcon>
        </FilterItemMotion>
      )}

      <JobCategoriesModal
        isShowModal={isShowJobCategoriesModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};
