import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';

export const useCheckboxPopperContentStyles = makeStyles(() => ({
  item: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.paleGrey.main,
    },
  },
  checkbox: {
    marginBottom: 0,
  },
  menuContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: 240,
  },
}));
