import React, { FC, forwardRef } from 'react';
import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Divider,
  Box,
} from '@mui/material';
import { Button } from '@nploy/ui/web';
import useTranslate from 'utils/i18n/useTranslate';
import {
  IFiltersPopper,
  IFiltersPopperContent,
} from './filters-popper.interface';
import { useFiltersPopperStyles } from './filters-popper.styles';

const Content = forwardRef<HTMLUListElement, IFiltersPopperContent>(
  ({ open, onListKeyDown, onSave, popperContent }, ref) => {
    const { t } = useTranslate();

    return (
      <MenuList ref={ref} autoFocusItem={open} onKeyDown={onListKeyDown}>
        <div>
          {popperContent}

          <Box mt={1} mb={2}>
            <Divider />
          </Box>

          <Box pl={2} pr={2}>
            <Button onClick={onSave} size="small">
              {t('save')}
            </Button>
          </Box>
        </div>
      </MenuList>
    );
  },
);

export const FiltersPopper: FC<IFiltersPopper> = ({
  open,
  popperContent,
  currentAnchorEl,
  onClose,
  onListKeyDown,
  onSave,
  PaperProps,
}) => {
  const classes = useFiltersPopperStyles();

  return (
    <Popper
      open={open}
      anchorEl={currentAnchorEl}
      transition
      disablePortal
      style={{ zIndex: 1 }}
      placement="bottom-start"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
          <Paper classes={{ root: classes.menuContainer }} {...PaperProps}>
            <ClickAwayListener onClickAway={onClose}>
              <Content
                open={open}
                onListKeyDown={onListKeyDown}
                onSave={onSave}
                popperContent={popperContent}
              />
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

// Needed to forward refs for click away listener
Content.displayName = 'FiltersPopperContent';

Content.defaultProps = {
  onListKeyDown: () => {
    // do nothing
  },
};

FiltersPopper.defaultProps = {
  currentAnchorEl: {},
  onListKeyDown: () => {
    // do nothing
  },
  PaperProps: {},
};
