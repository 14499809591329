import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { TextField } from '@nploy/ui/web';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';

const SearchCities = ({ cities, onChange, selectedCities, onDelete }) => {
  const { t } = useTranslate();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [val, setVal] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setVal(selectedCities);
  }, [selectedCities]);

  const handleOpen = () => {
    if (inputValue.length) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleChange = (event, newValue) => {
    setVal(newValue);
    onChange(newValue);
  };

  const handleDelete = (city) => {
    setVal(val.filter((entry) => entry !== city));
    onDelete(city);
  };

  return (
    <Box className={classes.container}>
      <Autocomplete
        id="search-cities"
        multiple
        freeSolo
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        style={{ width: '100%', height: 48 }}
        ListboxProps={{
          style: { maxHeight: '15rem' },
          position: 'bottom-start',
        }}
        classes={{ popper: classes.popper }}
        options={cities}
        getOptionLabel={(option) => option.name}
        onInputChange={handleInputChange}
        onChange={handleChange}
        getOptionDisabled={(option) =>
          !!selectedCities.find((city) => city.id === option.id)
        }
        value={val}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              field={{ ...params.inputProps }}
              variant="standard"
              placeholder={t('location.search')}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Box p={2} pr={1} style={{ height: 48 }}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      color={palette.darkerGrey.main}
                      size="lg"
                    />
                  </Box>
                ),
                endAdornment: null,
              }}
            />
          );
        }}
      />

      <Box pt={1} className={classes.selectedCitiesContainer}>
        {selectedCities.map((city) => {
          return (
            <Chip
              key={city.id}
              label={city.name}
              onDelete={() => handleDelete(city)}
              className={classes.chip}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: palette.common.white,
    border: '1px solid',
    borderColor: palette.grey.main,
    borderRadius: 28,
  },
  selectedCitiesContainer: {
    minHeight: 50,
  },
  popper: {
    zIndex: 1400,
  },
}));

SearchCities.propTypes = {
  cities: PropTypes.array.isRequired,
  selectedCities: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SearchCities;
