import React, { useState, useCallback } from 'react';
import { Chip, Typography } from '@mui/material';
import { Button, ButtonIcon } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import palette from 'theme/palette';
import { filtersValuesFields } from 'constants/filters-values-fields';
import useTranslate from 'utils/i18n/useTranslate';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { FilterItemMotion } from 'components/Filters/filter-item-motion';
import { KeywordsModal } from '../keywords-modal';
import { useStyles } from './keywords.styles';

export const Keywords = () => {
  const classes = useStyles();
  const { t } = useTranslate('home');

  const [isShowKeywordsModal, setShowKeywordsModal] = useState(false);

  const {
    filtersValues: { keywords },
    saveFieldsWithAllFilters,
  } = useFiltersValues();

  const handleOpenModal = useCallback(() => setShowKeywordsModal(true), []);

  const handleCloseModal = useCallback(() => setShowKeywordsModal(false), []);

  const handleDeleteKeyword = (keyword: string) => {
    const filteredSelectedKeywords = (keywords || []).filter(
      (selectedKeyword: string) => selectedKeyword !== keyword,
    );

    saveFieldsWithAllFilters({
      [filtersValuesFields.keywords]: filteredSelectedKeywords,
    });
  };

  return (
    <>
      <FilterItemMotion>
        <Button
          onClick={handleOpenModal}
          color="inherit"
          className={classes.button}
          size="small"
          id="add-keywords-button"
          area-label="add-keywords"
        >
          <Typography variant="button" className={classes.buttonText}>
            {t('filters.keywords.addKeyword')}
          </Typography>
        </Button>
      </FilterItemMotion>

      {(keywords || []).map((keyword) => (
        <FilterItemMotion key={`${keyword}`}>
          <Chip
            label={keyword}
            className={classes.chip}
            deleteIcon={
              <ButtonIcon
                className={classnames(classes.deleteButton, 'delete-icon')}
              >
                <FontAwesomeIcon
                  icon={faTimes as IconProp}
                  color={palette.darkerGrey.main}
                  size="xs"
                />
              </ButtonIcon>
            }
            classes={{ deleteIcon: classes.deleteIcon }}
            onDelete={() => handleDeleteKeyword(keyword)}
          />
        </FilterItemMotion>
      ))}

      <KeywordsModal
        handleCloseModal={handleCloseModal}
        isShowModal={isShowKeywordsModal}
        savedKeywords={keywords}
        saveFieldsWithAllFilters={saveFieldsWithAllFilters}
      />
    </>
  );
};
