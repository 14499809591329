import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';

export const useStyles = makeStyles(
  () => ({
    divider: {
      display: 'block',
      width: 24,
      height: 16,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        height: 0,
      },
    },
  }),
  {
    classNamePrefix: 'divider',
  },
);
