import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { Filters } from 'components/Filters';
import { IFindJobsPanel } from './find-jobs-panel.interface';

export const FindJobsPanel: FC<PropsWithChildren<IFindJobsPanel>> = ({
  children,
  showDetails,
}) => (
  <Box position="relative" mt={{ md: 3 }} mb={{ md: 3 }} component="section">
    <Filters showingDetails={showDetails} />

    {children}
  </Box>
);

FindJobsPanel.defaultProps = {
  children: null,
};

export default FindJobsPanel;
