import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { makeStyles } from '@mui/styles';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import useTranslate from 'utils/i18n/useTranslate';

const PopularCities = ({ cities, selectedCities, onClickPopularCity }) => {
  const { t } = useTranslate();
  const classes = useStyles();

  return (
    <List
      dense
      subheader={
        <ListSubheader classes={{ root: classes.subheader }}>
          {t('location.popularCities').toUpperCase()}
        </ListSubheader>
      }
      style={{ width: '100%' }}
    >
      {cities.map((city) => {
        const isSelected = selectedCities.find(({ id }) => id === city.id);

        return (
          <ListItem
            dense
            key={city.id}
            button
            classes={{ button: classes.itemList }}
            onClick={() => onClickPopularCity(city)}
          >
            <ListItemText
              id={city.id}
              primary={
                <Typography
                  style={{
                    fontWeight: 600,
                    color: isSelected ? palette.secondary.main : '',
                  }}
                >
                  {city.name}
                </Typography>
              }
            />
            {isSelected && (
              <ListItemSecondaryAction>
                <Box p={1}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={palette.secondary.main}
                    size="lg"
                  />
                </Box>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

const useStyles = makeStyles(() => ({
  itemList: {
    borderBottom: '1px solid',
    borderBottomColor: palette.grey.main,
    height: 48,
  },
  subheader: {
    borderBottom: '1px solid',
    borderBottomColor: palette.grey.main,
    height: 48,
    fonSize: '1rem',
    fontWeight: 400,
  },
}));

PopularCities.propTypes = {
  cities: PropTypes.array.isRequired,
  selectedCities: PropTypes.array.isRequired,
  onClickPopularCity: PropTypes.func.isRequired,
};

export default PopularCities;
