import { motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';
import { IFilterItemMotion } from './filter-item-motion.interface';

const spring = {
  type: 'spring',
  duration: 0.5,
};

export const FilterItemMotion: FC<PropsWithChildren<IFilterItemMotion>> = ({
  children,
  className,
}) => (
  <motion.div
    style={{ display: 'inline-block' }}
    layout
    transition={spring}
    className={className}
  >
    {children}
  </motion.div>
);
