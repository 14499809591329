export const searchCountryStyles = {
  container: {
    width: '100%',
  },
  popper: {
    '&.MuiAutocomplete-popper': {
      zIndex: 1400,
    },
  },
};
