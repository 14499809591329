import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const FilterContainer = ({ children }) => {
  return (
    <Box
      mb={{ xs: 2, md: 0 }}
      mr={3}
      display="flex"
      flexWrap="wrap"
      position="relative"
    >
      {children}
    </Box>
  );
};

FilterContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  wrappable: PropTypes.bool,
};

FilterContainer.defaultProps = {
  wrappable: false,
};

export default FilterContainer;
