import palette from 'theme/palette';

export const locationModalStyles = {
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: {
    marginBottom: 36,
  },
  itemList: {
    borderBottom: '1px solid',
    borderBottomColor: palette.grey.main,
    height: 48,
  },
};
