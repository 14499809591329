import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import shadows from 'theme/shadows';
import theme from 'theme/theme';

export const salaryRangeStyles = {
  buttonText: {
    '&.MuiTypography-root': {
      fontSize: '0.875rem',
      color: theme.palette.common.black,
    },
  },
  buttonTextSuccess: {
    '&.MuiTypography-button': {
      color: theme.palette.success.main,
    },
  },
  sliderContainer: {
    width: '80vw',
    height: 70,
    px: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: 594,
    },
  },
  subTitle: {
    textAlign: 'center',
  },
};

export const useSalaryRangeStyles = makeStyles(() => ({
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    boxShadow: shadows[1],
    backgroundColor: theme.palette.common.white,
  },
  activeButton: {
    backgroundColor: palette.paleGrey.main,
  },
}));
