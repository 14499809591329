import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import shadows from 'theme/shadows';
import theme from 'theme/theme';
import typography from 'theme/typography';

export const filtersLocationStyles = {
  chip: {
    '&.MuiChip-root': {
      fontFamily: typography.fontFamily,
      backgroundColor: palette.common.white,
      color: palette.success.main,
      borderRadius: 28,
      marginBottom: 1.6,
      marginRight: 1,
      boxShadow: shadows[1],
    },
  },
  buttonText: {
    fontSize: '0.875rem',
    color: palette.common.black,
  },
};
export const useFiltersLocationStyles = makeStyles(() => ({
  buttonIcon: {
    marginBottom: theme.spacing(1),
    boxShadow: shadows[1],
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    boxShadow: shadows[1],
    backgroundColor: palette.common.white,
  },
}));
