/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, FC } from 'react';
import { Autocomplete, Box } from '@mui/material';
import { ICandidateCountryFullDto } from '@nploy/ui-infrastructure';
import { ITextField, TextField } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import useTranslate from 'utils/i18n/useTranslate';
import { searchCountryStyles } from 'components/Filters/Location/search-country/search-country.styles';
import { ISearchCountry } from './search-country.interface';

export const SearchCountry: FC<ISearchCountry> = ({
  countries,
  onChange,
  selectedCountry,
}) => {
  const { t } = useTranslate();
  const [inputValue, setInputValue] = useState('');
  const [val, setVal] = useState<ICandidateCountryFullDto>(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    countries.sort((firstCountry, secondCountry) =>
      firstCountry.name > secondCountry.name ? 1 : -1,
    );
  }, []);

  useEffect(() => {
    setVal(selectedCountry);
  }, [selectedCountry]);

  const handleOpen = () => {
    if (inputValue.length) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (_, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleChange = (_, newValue: ICandidateCountryFullDto) => {
    setVal(newValue);
    onChange(newValue);
  };

  return (
    <Box sx={searchCountryStyles.container}>
      <Autocomplete
        id="search-country"
        freeSolo
        componentsProps={{
          popper: {
            sx: searchCountryStyles.popper,
          },
        }}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        options={countries}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        onInputChange={handleInputChange}
        onChange={handleChange}
        value={val}
        renderInput={(params) => (
          <TextField
            {...params}
            field={params.inputProps as ITextField['field']}
            variant="standard"
            placeholder={t('location.searchCountry')}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box p={2} pr={1} style={{ height: 48 }}>
                  <FontAwesomeIcon
                    icon={faSearch as IconProp}
                    color={palette.darkerGrey.main}
                    size="lg"
                  />
                </Box>
              ),
              endAdornment: null,
            }}
          />
        )}
      />
    </Box>
  );
};
