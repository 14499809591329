/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  FC,
  memo,
} from 'react';
import { Box } from '@mui/material';
import { sortByProperty } from '@nploy/shared';
import { ICandidateSelectedCityDto } from '@nploy/ui-infrastructure';
import { Button } from '@nploy/ui/web';
import useTranslate from 'utils/i18n/useTranslate';
import { useCities } from 'utils/swr/hooks/useCities';
import { useFilters } from 'utils/swr/hooks/useFilters';
import { ModalWithCloseOnBack } from 'components/ui/';
import PopularCities from '../PopularCities';
import SearchCities from '../SearchCities';
import { SearchCountry } from '../search-country';
import { ILocationModal } from './location-modal.interface';
import { locationModalStyles } from './location-modal.styles';

export const LocationModal: FC<ILocationModal> = memo(
  ({ handleCloseModal, isShowModal, savedCountryId, savedCities, onSave }) => {
    const { t } = useTranslate();

    const {
      filters: { countries },
    } = useFilters();
    const { cities } = useCities();

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCities, setSelectedCities] = useState([]);

    useEffect(() => {
      (cities || []).sort((firstCity, secondCity) =>
        firstCity.name > secondCity.name ? 1 : -1,
      );
    }, []);

    // Set selected country on initial load
    useEffect(() => {
      if (savedCountryId) {
        const country = countries.find((c) => c.id === savedCountryId);
        if (country) {
          setSelectedCountry(country);
        }
      }
    }, [savedCountryId, isShowModal]);

    // Set selected cities on initial load
    useEffect(() => {
      setSelectedCities(savedCities);
    }, [savedCities, isShowModal]);

    const countryCities = useMemo(
      () =>
        (cities || []).filter(
          ({ country_id }) => country_id === selectedCountry?.id,
        ),
      [selectedCountry, cities],
    );

    const popularCities = useMemo(
      () =>
        sortByProperty(
          countryCities.filter(({ popular }) => popular),
          'popular',
        ).reverse(),
      [countryCities],
    );

    const handleSelectCountry = (country) => {
      setSelectedCountry(country);
      if (selectedCities.length > 0) setSelectedCities([]);
    };

    const handleSelectCity = (
      newSelectedCities: ICandidateSelectedCityDto[],
    ) => {
      setSelectedCities(newSelectedCities);
    };

    const handleDeleteCity = (cityToDelete: ICandidateSelectedCityDto) => {
      const filteredSelectedCities = selectedCities.filter(
        ({ id }) => id !== cityToDelete.id,
      );
      setSelectedCities(filteredSelectedCities);
    };

    const handleSelectPopularCity = useCallback(
      (city: ICandidateSelectedCityDto) => {
        if (selectedCities.find(({ id }) => id === city.id)) {
          setSelectedCities(selectedCities.filter(({ id }) => id !== city.id));
        } else {
          setSelectedCities([...selectedCities, city]);
        }
      },
      [selectedCities],
    );

    const handleSave = () => {
      onSave(
        { cities: selectedCities, country_id: selectedCountry?.id },
        handleCloseModal,
      );
    };

    return (
      <ModalWithCloseOnBack
        open={isShowModal}
        onClose={handleCloseModal}
        title={t('location.title')}
        fixedWidth
        PaperProps={{
          id: 'add-location',
          'aria-labelledby':
            'add-location-button add-location-alternative-button',
        }}
        titleComponent="h1"
      >
        <>
          {/* Search country */}
          <Box sx={locationModalStyles.wrap}>
            <SearchCountry
              countries={countries}
              onChange={handleSelectCountry}
              selectedCountry={selectedCountry}
            />
          </Box>

          {/* Search city */}
          <Box sx={locationModalStyles.wrap}>
            <SearchCities
              cities={countryCities}
              onChange={handleSelectCity}
              selectedCities={selectedCities}
              onDelete={handleDeleteCity}
            />
          </Box>

          {/* Popular cities */}
          <Box sx={locationModalStyles.wrap}>
            <PopularCities
              cities={popularCities}
              selectedCities={selectedCities}
              onClickPopularCity={handleSelectPopularCity}
            />
          </Box>

          <Box display="flex" justifyContent="center" mt={7}>
            <Button onClick={handleSave}>{t('save')}</Button>
          </Box>
        </>
      </ModalWithCloseOnBack>
    );
  },
);
