import React, { FC } from 'react';
import { MenuItem } from '@mui/material';
import { Checkbox } from '@nploy/ui/web';
import { ICheckboxPopperContent } from './checkbox-popper-content.interface';
import { useCheckboxPopperContentStyles } from './checkbox-popper-content.styles';

export const CheckboxPopperContent: FC<ICheckboxPopperContent> = ({
  items,
  selectedItems,
  onItemSelect,
}) => {
  const classes = useCheckboxPopperContentStyles();

  const handleItemSelect = (e, id) => {
    e.stopPropagation();
    onItemSelect(id);
  };

  return (
    <>
      {items.map(({ id, name }) => (
        <MenuItem
          key={id}
          classes={{ root: classes.item }}
          onClick={(e) => handleItemSelect(e, id)}
        >
          <Checkbox
            field={{
              name,
              // We handle menu item click
              onChange: () => {
                // do nothing
              },
              checked: !!selectedItems.find((itemId) => itemId === id),
            }}
            label={name}
            className={classes.checkbox}
            labelClickDisabled
            meta={{
              error: '',
              touched: false,
            }}
          />
        </MenuItem>
      ))}
    </>
  );
};
