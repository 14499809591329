import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@nploy/shared';
import {
  ICandidateExternalJobDto,
  ICandidateJobDto,
  IFiltersValuesDto,
} from '@nploy/ui-infrastructure';
import { getFromLocalStorage, localStorageKeys } from 'utils/localStorage';
import {
  transformFiltersToExternalJobs,
  transformFiltersValuesToSend,
} from 'utils/swr/helpers';
import { shouldFetchIfHasToken } from 'utils/swr/helpers/shouldFetch';
import {
  resetJobs as resetJobsAction,
  setDislikedIds,
  setExternalDislikedIds,
  setExternalJobs,
  setExternalWithoutIds,
  setJobs,
  setJobsLoaded,
  setJobsLoading,
  setJobsWithReset,
  setLikedIds,
  setWithoutIds,
} from 'store/reducers/jobsReducer';
import { AppDispatch, RootState } from 'store/store';
import { openFeedback } from '../reducers/feedbackReducer';

export const JOBS_PER_PAGE = 10;
export const MIN_JOBS_ON_SCREEN = 5;

const getInitialDislikedIds = () =>
  getFromLocalStorage(localStorageKeys.dislikedIds) || [];
const getInitialExternalDislikedIds = () =>
  getFromLocalStorage(localStorageKeys.externalDislikedIds) || [];

export const setInitialDislikedJobs = (dispatch: AppDispatch) => {
  const initialDislikedIds = getInitialDislikedIds();
  const initialExternalDislikedIds = getInitialExternalDislikedIds();

  dispatch(setDislikedIds(initialDislikedIds));
  dispatch(setExternalDislikedIds(initialExternalDislikedIds));
};

export const shouldLoadMore = ({
  filtersLoading,
  loading,
  hasNextPage,
  jobs,
  pageIndex,
  filteredJobs,
  hasExternalNextJobsPage,
}) =>
  !filtersLoading &&
  !loading &&
  (hasNextPage || hasExternalNextJobsPage) &&
  jobs.length &&
  pageIndex * JOBS_PER_PAGE === jobs.length &&
  filteredJobs.length < MIN_JOBS_ON_SCREEN;

export const fetchExternalJobs = createAsyncThunk<
  void,
  { filtersValues: IFiltersValuesDto; page: number; without: number[] },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'jobs/fetchExternalJobs',
  async ({ filtersValues, without, page }, { dispatch }) => {
    dispatch(setJobsLoading());
    const { data } = await axios.get<{
      data: ICandidateExternalJobDto[];
      has_next_page: boolean;
    }>('jobs/scraper', {
      params: {
        page,
        per_page: JOBS_PER_PAGE,
        ...transformFiltersToExternalJobs(filtersValues),
        without,
      },
    });
    const jobsWithCompany: ICandidateJobDto[] = data.data.map((job) => ({
      ...job,
      dialog_info: null,
      salary_range_hidden: Number(job.salary_range_hidden),
      reason_id: Number(job.reason_id),
      candidate_job_profile_open: Number(job.candidate_job_profile_open),
      isExternal: true,
      description: job.description_html,
    }));
    dispatch(
      setExternalJobs({
        externalJobs: jobsWithCompany,
        hasExternalNextJobsPage: data.has_next_page,
        page,
      }),
    );
  },
);

export const getJobs = createAsyncThunk<
  void,
  IFiltersValuesDto,
  { dispatch: AppDispatch; state: RootState }
>('jobs/getJobs', async (filtersValues, { getState, dispatch }) => {
  try {
    const state = getState();
    const {
      hasNextPage,
      loading,
      pageIndex,
      without: currentWithout,
      dislikedIds,
      hasExternalNextJobsPage,
      externalJobsPageIndex,
      externalWithout: currentExternalWithout,
    } = state.jobs;

    if ((!hasNextPage && !hasExternalNextJobsPage) || loading) return;
    let without = currentWithout;
    let withoutExternal = currentExternalWithout;

    if (hasNextPage) {
      dispatch(setJobsLoading());

      const page = pageIndex + 1;

      const isAuthed = shouldFetchIfHasToken();

      if (page === 1) {
        if (isAuthed) {
          const [{ data }, externalWithout] = await Promise.all([
            axios.get<number[]>(
              `job/ids_to_skip?&timestamp=${new Date().getTime()}`,
            ),
            axios.get<{ data: number[] }>('jobs/scraper-job/id-to-skip'),
          ]);
          withoutExternal = externalWithout.data.data;
          without = data;
        } else {
          without = dislikedIds?.length ? without : getInitialDislikedIds();
          withoutExternal = currentExternalWithout.length
            ? currentExternalWithout
            : getInitialExternalDislikedIds();
        }

        dispatch(setWithoutIds(without));
        dispatch(setExternalWithoutIds(withoutExternal));
      }

      const { data } = await axios.get<{
        data: ICandidateJobDto[];
        has_next_page: boolean;
      }>(
        isAuthed
          ? `job/authed?&timestamp=${new Date().getTime()}`
          : `jobs?&timestamp=${new Date().getTime()}`,
        {
          params: {
            page,
            per_page: JOBS_PER_PAGE,
            ...transformFiltersValuesToSend(filtersValues),
            without,
          },
        },
      );
      // Clear liked & disliked for authed use on job reset
      if (isAuthed && page === 1) {
        dispatch(setLikedIds([]));
        dispatch(setDislikedIds([]));
      }
      if (page === 1) {
        dispatch(
          setJobsWithReset({
            jobs: data.data,
            hasNextPage: data.has_next_page,
            page,
          }),
        );
      } else {
        dispatch(
          setJobs({ jobs: data.data, hasNextPage: data.has_next_page, page }),
        );
      }

      if (!data.has_next_page) {
        dispatch(
          fetchExternalJobs({
            without: [...new Set(withoutExternal)],
            filtersValues,
            page: externalJobsPageIndex + 1,
          }),
        );
      }
    } else if (hasExternalNextJobsPage) {
      dispatch(
        fetchExternalJobs({
          without: [...new Set(withoutExternal)],
          filtersValues,
          page: externalJobsPageIndex + 1,
        }),
      );
    }
  } catch (error) {
    dispatch(openFeedback({ type: 'error', message: error.message }));
    dispatch(setJobsLoaded);
  }
});

export const resetJobs = createAsyncThunk(
  'jobs/reset',
  async (filtersValues: IFiltersValuesDto, { dispatch }) => {
    dispatch(resetJobsAction());
    await dispatch(getJobs(filtersValues));
  },
);
