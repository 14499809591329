import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';

export const useFiltersPopperStyles = makeStyles(() => ({
  menuContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: 240,
  },
}));
