import React, { useState, useCallback, FC, useMemo } from 'react';
import { Chip, Typography } from '@mui/material';
import { Button, ButtonIcon } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import useTranslate from 'utils/i18n/useTranslate';
import { useFilters } from 'utils/swr/hooks/useFilters';
import { FilterItemMotion } from 'components/Filters/filter-item-motion';
import { LocationModal } from '../location-modal';
import { IFiltersLocation } from './filters-location.interface';
import {
  filtersLocationStyles,
  useFiltersLocationStyles,
} from './filters-location.styles';

export const FiltersLocation: FC<IFiltersLocation> = ({
  selectedCities,
  selectedCountry,
  onSave,
  buttonText = 'filters.location.addCity',
}) => {
  const classes = useFiltersLocationStyles();
  const { t } = useTranslate('home');

  const [isShowLocationModal, setShowLocationModal] = useState(false);

  const handleOpenModal = useCallback(() => setShowLocationModal(true), []);

  const handleCloseModal = useCallback(() => setShowLocationModal(false), []);

  const {
    filters: { countries },
  } = useFilters();

  const currentCountry = useMemo(
    () => (countries || []).find((country) => country.id === selectedCountry),
    [countries, selectedCountry],
  );

  return (
    <>
      {selectedCountry ? (
        <>
          {selectedCities?.length ? (
            selectedCities.map((city) => (
              <FilterItemMotion key={city.id}>
                <Chip label={city.name} sx={filtersLocationStyles.chip} />
              </FilterItemMotion>
            ))
          ) : (
            <FilterItemMotion>
              <Chip
                label={currentCountry.name}
                sx={filtersLocationStyles.chip}
              />
            </FilterItemMotion>
          )}

          <FilterItemMotion>
            <ButtonIcon
              onClick={handleOpenModal}
              className={classes.buttonIcon}
              id="add-location-alternative-button"
              area-label="add-location"
            >
              <FontAwesomeIcon
                icon={faPlus as IconProp}
                color={palette.darkerGrey.main}
                size="xs"
              />
            </ButtonIcon>
          </FilterItemMotion>
        </>
      ) : (
        <FilterItemMotion>
          <Button
            size="small"
            color="inherit"
            className={classes.button}
            onClick={handleOpenModal}
            id="add-location-button"
            area-label="add-location"
          >
            <Typography variant="button" sx={filtersLocationStyles.buttonText}>
              {t(buttonText)}
            </Typography>
          </Button>
        </FilterItemMotion>
      )}

      <LocationModal
        handleCloseModal={handleCloseModal}
        isShowModal={isShowLocationModal}
        onSave={onSave}
        savedCities={selectedCities}
        savedCountryId={selectedCountry}
      />
    </>
  );
};
