import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import { useCountry } from 'utils/swr/hooks/useContry';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { useUser } from 'utils/swr/hooks/useUser';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import {
  getJobs,
  resetJobs,
  setInitialDislikedJobs,
  shouldLoadMore,
} from 'store/actions/jobsActions';

export const withFindJobs = (Component: FC) => (): ReactNode => {
  const {
    jobs,
    filteredJobs,
    loading,
    pageIndex,
    hasNextPage,
    hasExternalNextJobsPage,
  } = useAppSelector(({ jobs: stateJobs }) => stateJobs);
  const dispatch = useAppDispatch();
  const { loading: filtersLoading, filtersValues } = useFiltersValues();
  const { isAuthed, loading: userLoading } = useUser();
  useCountry();

  const handleLoadMoreJobs = useCallback(() => {
    dispatch(getJobs(filtersValues));
  }, [filtersValues]);

  // Load jobs when filters are loaded and changed
  useEffect(() => {
    if (!filtersLoading) {
      dispatch(resetJobs(filtersValues));
    }
  }, [filtersLoading, filtersValues]);

  // Fetch more jobs if not fetching, there are more jobs, number of already fetched equals current page * per page and filtered length is not enough for screen
  useEffect(() => {
    if (
      shouldLoadMore({
        filtersLoading,
        loading,
        hasNextPage,
        hasExternalNextJobsPage,
        jobs,
        pageIndex,
        filteredJobs,
      })
    ) {
      handleLoadMoreJobs();
    }
  }, [filteredJobs, hasNextPage, loading, pageIndex, jobs]);

  // Set initial disliked jobs from storage for not authed user
  useEffect(() => {
    if (!userLoading && !isAuthed) {
      setInitialDislikedJobs(dispatch);
    }
  }, [userLoading, isAuthed]);

  return <Component />;
};
