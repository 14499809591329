import React, { useRef, useState, useEffect, memo, FC } from 'react';
import { Box, Typography } from '@mui/material';
import { transformNumber } from '@nploy/shared';
import { Button, SalaryLabelFormat, Slider } from '@nploy/ui/web';
import classnames from 'classnames';
import useTranslate from 'utils/i18n/useTranslate';
import { useFilters } from 'utils/swr/hooks/useFilters';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import FilterContainer from '../FilterContainer/FilterContainer';
import { FiltersPopper } from '../filters-popper';
import { salaryRangeStyles, useSalaryRangeStyles } from './salary-range.styles';

export const SalaryRange: FC = memo(() => {
  const classes = useSalaryRangeStyles();
  const { t } = useTranslate('home');

  const {
    filtersValues: {
      salary_min: savedSalaryMin = 0,
      country_id: selectedCountryId,
    },
    saveSalary,
  } = useFiltersValues();
  const {
    filters: { countries },
  } = useFilters();

  const [open, setOpen] = useState(false);
  const [salary, setSalary] = useState([0, 0]);
  const [currency, setCurrency] = useState('');
  const [salaryRange, setSalaryRange] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (selectedCountryId && countries) {
      const selectedCountry = countries.find(
        ({ id }) => id === selectedCountryId,
      );

      const {
        salary_max_range: range,
        currency: countryCurrency,
        salary_min: salaryMin,
        salary_max: salaryMax,
      } = selectedCountry;
      setCurrency(countryCurrency);
      setMin(salaryMin);
      setMax(salaryMax);
      setSalaryRange(range);

      if (savedSalaryMin) {
        setSalary([savedSalaryMin]);
      } else {
        setSalary([min, min + range]);
      }
    }
  }, [selectedCountryId, countries, savedSalaryMin]);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleValueChange = (value) => {
    setSalary([value, value + salaryRange]);
  };

  const handleSave = () => {
    saveSalary(salary[0], salary[1], handleCloseMenu);
  };

  return (
    <FilterContainer>
      <Button
        ref={anchorRef}
        onClick={handleToggleMenu}
        color="inherit"
        className={classnames(classes.button, open && classes.activeButton)}
        size="small"
        id="change-salary-range-button"
        area-label="change-salary-range"
      >
        <Typography
          variant="button"
          sx={{
            ...salaryRangeStyles.buttonText,
            ...(savedSalaryMin && salaryRangeStyles.buttonTextSuccess),
          }}
        >
          {savedSalaryMin
            ? `${transformNumber(savedSalaryMin, ',')}+`
            : t('filters.salaryRange.title')}
        </Typography>
      </Button>

      <FiltersPopper
        open={open}
        currentAnchorEl={anchorRef.current}
        onListKeyDown={handleListKeyDown}
        onClose={handleCloseMenu}
        onSave={handleSave}
        popperContent={
          <>
            <Box sx={salaryRangeStyles.subTitle}>
              <Typography component="h2">
                {t('filters.salaryRange.selectSalaryRange')}
              </Typography>
            </Box>
            <Box display="flex" p={2} sx={salaryRangeStyles.sliderContainer}>
              <Slider
                min={min}
                max={max}
                value={salary}
                onChange={handleValueChange}
                LabelFormatComponent={(value) => (
                  <SalaryLabelFormat
                    value={value}
                    currency={currency}
                    translations={{
                      month: t('filters.salaryRange.month'),
                      year: t('filters.salaryRange.year'),
                    }}
                  />
                )}
              />
            </Box>
          </>
        }
        PaperProps={{
          id: 'change-salary-range',
          'area-labelledby': 'change-salary-range-button',
        }}
      />
    </FilterContainer>
  );
});
