import React, { FC } from 'react';
import { Box, Collapse, Container, useMediaQuery } from '@mui/material';
import { Button } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSlidersV, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import {
  closeFiltersOnMobile,
  openFiltersOnMobile,
} from 'store/reducers/uiReducer';
import { FilterItemMotion } from 'components/Filters/filter-item-motion';
import { FiltersJobCategories } from 'components/Filters/filters-job-categories';
import { FiltersJobType } from 'components/Filters/job-type';
import { FiltersWorkModel } from 'components/Filters/work-model/work-model';
import Experience from '../Experience/Experience';
import { FiltersLocation } from '../Location/filters-location';
import { FiltersBlocksDivider } from '../filter-blocks-divider';
import { Keywords } from '../keywords/keywords';
import { SalaryRange } from '../salary-range';
import { WorkingTime } from '../working-time';
import { IFilters } from './filters.interface';
import { useFiltersStyles } from './filters.styles';

export const Filters: FC<IFilters> = ({ showingDetails }) => {
  const classes = useFiltersStyles();
  const { t } = useTranslate('home');
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const {
    filtersValues: { cities, country_id: countryId },
    saveLocation,
  } = useFiltersValues();
  const showFiltersOnMobile = useAppSelector(
    ({ ui }) => ui.openFiltersOnMobile,
  );

  const toggleFiltersOnMobile = () => {
    if (showFiltersOnMobile) {
      dispatch(closeFiltersOnMobile());
    } else {
      dispatch(openFiltersOnMobile());
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.filtersBlock}>
        {/* Button only on xs and sm */}
        {!isDesktop && (
          <Box alignSelf="center" mt={2} mb={2}>
            <Button
              color="secondary"
              size="small"
              onClick={toggleFiltersOnMobile}
              startIcon={
                <Box width={20}>
                  <FontAwesomeIcon
                    icon={
                      (showFiltersOnMobile ? faTimes : faSlidersV) as IconProp
                    }
                  />
                </Box>
              }
              id="toggle-show-filters-button"
              area-label="filters"
            >
              {t('filters.title')}
            </Button>
          </Box>
        )}

        <Collapse in={showFiltersOnMobile || isDesktop}>
          <Box
            className={classes.filtersContainer}
            id="filters"
            aria-labelledby="toggle-show-filters-button"
          >
            <Container className={classes.materialContainer}>
              <Box width="100%" display="flex">
                <Box
                  className={classnames(
                    classes.filtersRow,
                    showingDetails && classes.filtersRowShrinked,
                  )}
                >
                  <FiltersLocation
                    selectedCities={cities}
                    selectedCountry={countryId}
                    onSave={saveLocation}
                  />
                  <FiltersBlocksDivider />

                  <FiltersJobCategories />
                  <FiltersBlocksDivider />

                  <Keywords />
                  <FiltersBlocksDivider />
                </Box>
              </Box>

              <Box width="100%" display="flex">
                <Box
                  className={classnames(
                    classes.filtersRow,
                    showingDetails && classes.filtersRowShrinked,
                  )}
                >
                  <FilterItemMotion>
                    <Box display="flex" flexWrap="wrap">
                      <WorkingTime />

                      <SalaryRange />

                      <Experience />

                      <FiltersJobType />
                      <FiltersWorkModel />
                    </Box>
                  </FilterItemMotion>
                </Box>
              </Box>
            </Container>
          </Box>

          {/* Shadow bottom shadow for mobile view */}
          <Box
            display={{ xs: 'block', md: 'none' }}
            className={classes.shadow}
          />
        </Collapse>
      </Box>
    </Box>
  );
};
