import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import theme from 'theme/theme';

export const useStyles = makeStyles(() => ({
  chip: {
    backgroundColor: palette.common.white,
    borderRadius: 28,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[1],
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    position: 'relative',
    maxWidth: 300,
    '&:hover .delete-icon': {
      backgroundColor: palette.grey.main,
      opacity: 1,
    },
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[1],
    backgroundColor: palette.common.white,
  },
  buttonText: {
    fontSize: '0.875rem',
    color: theme.palette.common.black,
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    backgroundColor: palette.grey.main,
    opacity: 0,
    transition: '500ms',
  },
  deleteIcon: {
    marginRight: 0,
  },
}));
