import palette from 'theme/palette';
import theme from 'theme/theme';

export const jobCategoriesModalStyles = {
  modal: {
    minWidth: '100%',
    minHeight: '100%',
    backgroundColor: palette.pageBackgroundGrey.main,
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialogContent-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
};
