/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import { Container, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonProps } from '@nploy/ui/domain';
import { Button } from '@nploy/ui/web';
import theme from 'theme/theme';
import { filtersValuesFields } from 'constants/filters-values-fields';
import useTranslate from 'utils/i18n/useTranslate';
import { useFilters } from 'utils/swr/hooks/useFilters';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { useAppSelector, useAppDispatch } from 'hooks/*';
import {
  closeFiltersOnMobile,
  hideJobTypeFilter,
  openFiltersOnMobile,
  openJobTypeFilter,
} from 'store/reducers/uiReducer';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: '#150090',
    minHeight: '60px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  text: {
    fontWeight: 500,
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    color: '#00ECB2',
  },
  buttonTextManagerForADay: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 600,
    fontSize: '1em',
  },
}));

const Banner: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslate('home');
  const buttonRef = useRef(null);
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const employeeTypeManagerForADayId = 7;
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    filters: { employ_types: employment_types = [] },
  } = useFilters();
  const showModal = useAppSelector(({ ui }) => ui.openJobTypeFilter);
  const { saveFieldsWithAllFilters } = useFiltersValues();
  const handleSave = async () => {
    await saveFieldsWithAllFilters({
      [filtersValuesFields.employment_types]: [
        employment_types[5]?.id || employeeTypeManagerForADayId,
      ],
    });
  };

  const handleClickButton = async () => {
    if (showModal) {
      dispatch(hideJobTypeFilter());
      if (isMobile) {
        dispatch(closeFiltersOnMobile());
      }
    } else {
      await handleSave();
      dispatch(openJobTypeFilter());
      if (isMobile) {
        dispatch(openFiltersOnMobile());
      }
    }
  };

  const filterButtonProps: ButtonProps = {
    size: 'medium',
    onClick: handleClickButton,
    ref: buttonRef,
  };
  return (
    <Container className={classes.container} component="section">
      <p className={classes.text}>
        {t('filters.banner.titleFirstPart')}
        {` "`}
        <a
          href="https://mfd.jabulgaria.org/"
          target="_blank"
          className={classes.link}
          rel="noreferrer"
        >
          {t('filters.banner.managerForADay')}
          {`" `}
        </a>
        {t('filters.banner.titleSecondPart')}
      </p>
      <Button {...filterButtonProps}>
        <span className={classes.buttonTextManagerForADay}>
          {t('filters.banner.filterBtn')}
        </span>
      </Button>
    </Container>
  );
};
export default Banner;
