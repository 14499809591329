/* eslint-disable react/prop-types,react/jsx-props-no-spreading */
import React, { FC, forwardRef, memo, ReactElement, Ref } from 'react';
import { Dialog, DialogContent, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { JobCategories } from '../../JobCategories/job-categories';
import { IFiltersJobCategoriesModal } from './filters-job-categories-modal.interface';
import { jobCategoriesModalStyles } from './filters-job-categories-modal.styles';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
  ) => (
    <Slide direction="up" ref={ref} {...props} timeout={600}>
      {props.children}
    </Slide>
  ),
);

export const JobCategoriesModal: FC<IFiltersJobCategoriesModal> =
  memo<IFiltersJobCategoriesModal>(({ handleCloseModal, isShowModal }) => (
    <Dialog
      open={isShowModal}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        id: 'add-job-category',
        'aria-labelledby': 'add-job-category-button',
        sx: jobCategoriesModalStyles.modal,
      }}
    >
      <DialogContent sx={jobCategoriesModalStyles.modal}>
        <JobCategories
          inModal
          closeModal={handleCloseModal}
          modalOpened={isShowModal}
        />
      </DialogContent>
    </Dialog>
  ));
