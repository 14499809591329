import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from '@nploy/ui/web';
import classnames from 'classnames';
import shadows from 'theme/shadows';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';
import { useFilters } from 'utils/swr/hooks/useFilters';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { ExperienceSlider } from 'components/Filters/experience-slider';
import FilterContainer from '../FilterContainer/FilterContainer';
import { FiltersPopper } from '../filters-popper';

const Experience = () => {
  const classes = useStyles();
  const { t, lang } = useTranslate('home');

  const {
    filters: { seniority_levels },
  } = useFilters();
  const {
    filtersValues: {
      experience_years_min: savedExperienceMin = 0,
      experience_years_max: savedExperienceMax = 0,
    },
    saveFieldsWithAllFilters,
  } = useFiltersValues();

  const [open, setOpen] = useState(false);
  const [experience, setExperience] = useState(0);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (open) {
      const savedExperienceAvg = (savedExperienceMin + savedExperienceMax) / 2;

      const savedExperienceLevel = seniority_levels.findIndex(
        ({ min_year, max_year }) =>
          savedExperienceAvg >= min_year && savedExperienceAvg <= max_year,
      );

      setExperience(savedExperienceLevel > 0 ? savedExperienceLevel : 0);
    }
  }, [savedExperienceMin, savedExperienceMax, open]);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleValueChange = (value) => {
    setExperience(value);
  };

  const handleSave = () => {
    const selectedLevel = seniority_levels?.[experience] || {};
    const {
      min_year: experience_years_min = 0,
      max_year: experience_years_max = 0,
    } = selectedLevel;

    saveFieldsWithAllFilters(
      {
        experience_years_min,
        experience_years_max,
      },
      handleCloseMenu,
    );
  };

  const transformedSeniorityLevels = useMemo(
    () =>
      seniority_levels?.map((level) => ({
        ...level,
        name: t(`filters.experience.${level.name}`),
        min: level.min_year,
        max: level.max_year,
      })),
    [seniority_levels, lang],
  );

  return (
    <FilterContainer>
      <Button
        ref={anchorRef}
        onClick={handleToggleMenu}
        color="inherit"
        className={classnames(classes.button, open && classes.activeButton)}
        size="small"
        id="change-experience-button"
        area-label="change-experience"
      >
        <Typography variant="button" className={classes.buttonText}>
          {t('filters.experience.title')}
        </Typography>
      </Button>

      <FiltersPopper
        open={open}
        currentAnchorEl={anchorRef.current}
        onListKeyDown={handleListKeyDown}
        onClose={handleCloseMenu}
        onSave={handleSave}
        popperContent={
          <Box p={2} className={classes.sliderContainer}>
            <ExperienceSlider
              handleValueChange={handleValueChange}
              min={0}
              max={seniority_levels.length - 1}
              value={experience}
              levels={transformedSeniorityLevels}
              maxValue={seniority_levels.length - 1}
            />
          </Box>
        }
        PaperProps={{
          id: 'change-experience',
          'area-labelledby': 'change-experience-button',
        }}
      />
    </FilterContainer>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    boxShadow: shadows[1],
    backgroundColor: theme.palette.common.white,
  },
  activeButton: {
    backgroundColor: theme.palette.paleGrey.main,
  },
  buttonText: {
    fontSize: '0.875rem',
    color: theme.palette.common.black,
  },
  sliderContainer: {
    width: '98vw',
    height: 142,
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

export default Experience;
