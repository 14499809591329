/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback, FC } from 'react';
import { Box, Chip } from '@mui/material';
import { Button, TextField, ButtonIcon } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import { filtersValuesFields } from 'constants/filters-values-fields';
import useTranslate from 'utils/i18n/useTranslate';
import { ModalWithCloseOnBack } from 'components/ui/';
import { IKeywordsModal } from './keywords-modal.interfaces';
import { useStyles } from './keywords-modal.styles';

export const KeywordsModal: FC<IKeywordsModal> = ({
  handleCloseModal,
  isShowModal,
  savedKeywords = [],
  saveFieldsWithAllFilters,
}) => {
  const { t } = useTranslate();
  const { t: tHome } = useTranslate('home');
  const classes = useStyles();

  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [keyword, setKeyword] = useState('');
  const haveNotSavedKeyword = keyword && !selectedKeywords.includes(keyword);

  // Set selected keywords on initial load
  useEffect(() => {
    setSelectedKeywords(savedKeywords);
  }, [savedKeywords, isShowModal]);

  const handleChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleDeleteKeyword = (keywordArg: string) => {
    const filteredSelectedKeywords = selectedKeywords.filter(
      (selectedKeyword) => selectedKeyword !== keywordArg,
    );
    setSelectedKeywords(filteredSelectedKeywords);
  };

  const addKeyword = useCallback(() => {
    if (haveNotSavedKeyword) {
      setSelectedKeywords([...selectedKeywords, keyword]);
      setKeyword('');
    }
  }, [selectedKeywords, keyword]);

  const handleClose = () => {
    setKeyword('');
    handleCloseModal();
  };

  const handleSave = () => {
    saveFieldsWithAllFilters(
      {
        [filtersValuesFields.keywords]: haveNotSavedKeyword
          ? [...selectedKeywords, keyword]
          : selectedKeywords,
      },
      handleClose,
    );
  };

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addKeyword();
    }
  };

  const textFieldProps = {
    fullWidth: true,
    onKeyPress: handlePressKey,
    field: {
      onChange: handleChange,
      value: keyword,
      name: 'keywords field',
      onBlur: () => {},
    },
    variant: 'standard',
    placeholder: tHome('filters.keyword.addKeywordPlaceholder'),
    InputProps: {
      startAdornment: (
        <Box p={2} pr={1} style={{ height: 48 }}>
          <FontAwesomeIcon
            icon={faSearch as IconProp}
            color={palette.darkerGrey.main}
            size="lg"
          />
        </Box>
      ),
      endAdornment: !!keyword && (
        <ButtonIcon onClick={addKeyword} className={classes.buttonIcon}>
          <FontAwesomeIcon
            icon={faPlus as IconProp}
            color={palette.common.white}
            size="sm"
          />
        </ButtonIcon>
      ),
    },
  } as any;

  return (
    <ModalWithCloseOnBack
      open={isShowModal}
      onClose={handleClose}
      title={tHome('filters.keyword.title')}
      fixedWidth
      PaperProps={{
        id: 'add-keywords',
        'area-labelledby': 'add-keywords-button',
      }}
      titleComponent="h1"
    >
      <>
        {/* Add keywords */}
        <Box className={classes.wrap}>
          <TextField {...textFieldProps} />
        </Box>
        {!!selectedKeywords.length && (
          <Box>
            {selectedKeywords.map((keywordArg) => (
              <Chip
                key={`${keywordArg}`}
                label={keywordArg}
                onDelete={() => handleDeleteKeyword(keywordArg)}
                className={classes.chip}
              />
            ))}
          </Box>
        )}
        <Box display="flex" justifyContent="center" mt={7}>
          <Button onClick={handleSave}>{t('save')}</Button>
        </Box>
      </>
    </ModalWithCloseOnBack>
  );
};
