import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import theme from 'theme/theme';

export const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
  },
  chip: {
    marginRight: theme.spacing(1),
    backgroundColor: palette.paleGrey.main,
    borderRadius: 28,
    marginBottom: theme.spacing(1),
    maxWidth: 300,
    '&:focus': {
      backgroundColor: palette.paleGrey.main,
    },
  },
  buttonIcon: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));
