import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import shadows from 'theme/shadows';
import theme from 'theme/theme';
import typography from 'theme/typography';

export const useFiltersJobCategories = makeStyles(() => ({
  chip: {
    fontFamily: typography.fontFamily,
    backgroundColor: palette.common.white,
    borderRadius: 28,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: shadows[1],
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    position: 'relative',
    '&:hover .delete-icon': {
      backgroundColor: palette.grey.main,
      opacity: 1,
    },
  },
  buttonIcon: {
    marginBottom: theme.spacing(1),
    boxShadow: shadows[1],
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    backgroundColor: palette.grey.main,
    opacity: 0,
    transition: '500ms',
  },
  deleteIcon: {
    marginRight: 0,
  },
}));
