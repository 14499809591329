import React, {
  useRef,
  useState,
  useEffect,
  FC,
  memo,
  useContext,
} from 'react';
import { Typography } from '@mui/material';
import { Button } from '@nploy/ui/web';
import classnames from 'classnames';
import { StaticFiltersContext } from 'utils/context/static-filters-context';
import useTranslate from 'utils/i18n/useTranslate';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { CheckboxPopperContent } from 'components/Filters';
import { useJobTypeStyles } from 'components/Filters/job-type/job-type.styles';
import FilterContainer from '../FilterContainer/FilterContainer';
import { FiltersPopper } from '../filters-popper';

export const FiltersWorkModel: FC = memo(() => {
  const classes = useJobTypeStyles();
  const { t } = useTranslate('home');
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const {
    filtersValues: { work_models: savedWorkModels },
    saveWorkModels,
  } = useFiltersValues();
  const { workModels } = useContext(StaticFiltersContext);

  const [open, setOpen] = useState(false);
  const [selectedWorkModels, setSelectedWorkModels] = useState([]);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (savedWorkModels) {
      setSelectedWorkModels(savedWorkModels.map(({ id }) => id));
    }
  }, [open, isOpenMenu, savedWorkModels]);

  const handleToggleMenu = () => {
    setIsOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleItemSelect = (id: number) => {
    if (selectedWorkModels.includes(id)) {
      setSelectedWorkModels(
        selectedWorkModels.filter(
          (employmentTypeId) => employmentTypeId !== id,
        ),
      );
    } else {
      setSelectedWorkModels([...selectedWorkModels, id]);
    }
  };

  const handleSave = async () => {
    const validWorkModelsFormat = selectedWorkModels.map((modelId) =>
      workModels.find(({ id }) => id === modelId),
    );
    await saveWorkModels(validWorkModelsFormat);
    handleCloseMenu();
  };
  return (
    <FilterContainer>
      <Button
        ref={anchorRef}
        onClick={handleToggleMenu}
        color="inherit"
        className={classnames(classes.button, open && classes.activeButton)}
        size="small"
        id="change-job-types-button"
        area-label="change-job-types"
      >
        <Typography
          variant="button"
          className={classnames(
            classes.buttonText,
            !!savedWorkModels?.length && classes.buttonTextSuccess,
          )}
        >
          {t('filters.workModel.title')}{' '}
          {!!savedWorkModels?.length && `(${savedWorkModels.length})`}
        </Typography>
      </Button>

      <FiltersPopper
        open={isOpenMenu}
        currentAnchorEl={anchorRef.current}
        onListKeyDown={handleListKeyDown}
        onClose={handleCloseMenu}
        onSave={handleSave}
        popperContent={
          <CheckboxPopperContent
            items={workModels.map(({ name, ...type }) => ({
              ...type,
              name: t(`filters.workModel.${name}`),
            }))}
            selectedItems={selectedWorkModels}
            onItemSelect={handleItemSelect}
          />
        }
        PaperProps={{
          id: 'change-job-types',
          'aria-labelledby': 'change-job-types-button',
        }}
      />
    </FilterContainer>
  );
});
