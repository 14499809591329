import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import shadows from 'theme/shadows';
import theme from 'theme/theme';

export const useFiltersStyles = makeStyles(() => ({
  container: {
    height: 72,
    position: 'relative',
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.spacing(3),
      marginRight: -theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      marginLeft: 'unset',
      marginRight: 'unset',
    },
  },
  filtersBlock: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  filtersContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: palette.pageBackgroundGrey.main,
    [theme.breakpoints.up('sm')]: {
      boxShadow: 'none',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
    },
  },
  shadow: {
    boxShadow: shadows[16],
    backgroundColor: palette.pageBackgroundGrey.main,
    height: theme.spacing(5),
    width: '100%',
  },
  materialContainer: {
    [theme.breakpoints.up('md')]: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  filtersRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      flexGrow: 1,
    },
  },
  filtersRowShrinked: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
}));
