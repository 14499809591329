import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import shadows from 'theme/shadows';
import theme from 'theme/theme';

export const useWorkingTimeStyles = makeStyles(() => ({
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    boxShadow: shadows[1],
    backgroundColor: theme.palette.common.white,
  },
  activeButton: {
    backgroundColor: palette.paleGrey.main,
  },
  buttonText: {
    fontSize: '0.875rem',
    color: theme.palette.common.black,
  },
  buttonTextSuccess: {
    color: theme.palette.success.main,
  },
}));
